<template>
  <v-dialog
      v-bind="$attrs"
      v-on="$listeners"
      class="container"
      width="394"
  >
    <v-card class="container__card d-flex flex-column rounded-lg" width="100%">
      <v-form @submit.prevent="sendPaymentAlert" ref="form">
        <div class="pt-11 pb-5 px-10">
          <p class="font-weight-regular title text-left pa-0 ma-0">Enviar alerta de pago</p>
          <p class="body-2 mb-1 subtitle-2 font-weight-light">Se enviará al usuario un email con la fecha de pago y monto indicados</p>
        </div>
        <div class="container__item px-10">
          <v-text-field
              label="Monto"
              v-model="monto"
              dense
              outlined
              type="number"
              class="mb-2"
              :rules="[rules.required, rules.minValue(0), rules.maxValue(deuda)]"
              ref="total"
          />
          <v-menu
            v-model="showPaymentDate"
            :close-on-content-click="false"
            :nudge-right="15"
            transition="scale-transition"
            offset-y
            min-width="auto"
            dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedPaymentDate"
                label="Fecha de pago"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="paymentDate"
              @input="showPaymentDate = false"
              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="container__item pt-2 pb-11 px-9 d-flex justify-end">
          <v-btn class="mx-1" outlined @click="close">Cerrar</v-btn>
          <v-btn type="submit" :loading="loading" class="mx-2" color="primary">Guardar</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minValue, maxValue } from '@/utils/rules'
export default {
  data() {
    return {
      loading: false,
      paymentDate: null,
      showPaymentDate: null,
      rules: {
        required,
        minValue,
        maxValue
      },
      monto: 0
    }
  },
  props: {
    matricula: Object
  },
  computed: {
    formattedPaymentDate () {
      return this.formatDate(this.paymentDate)
    },
    deuda () {
      if (this.matricula && this.matricula.monto_total && this.matricula.monto_pagado) {
        return this.matricula.monto_total - this.matricula.monto_pagado
      }
      return 0
    }
  },
  methods: {
    async sendPaymentAlert () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const payload = {
            monto: this.monto,
            fecha_pago: `${this.paymentDate}`,
          }
          console.log(payload);
          await this.$http.post(`api/admin/matriculas/matriculas/${this.matricula.id}/send_alerta_pago/`, payload)
          this.resetForm()
          this.$emit('updated')
          await this.$root.$confirm({
            title: 'Mensaje',
            message: 'Alerta enviada.',
            acceptText: 'Ok',
          })
          this.loading = false
        } catch (e) {
          this.loading = false
          await this.$root.$confirm({
            title: '!Oops!',
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            acceptText: 'Ok',
          })
        }
      }
    },
    close () {
      this.resetForm()
      this.$emit('close')
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    resetForm () {
      this.paymentDate = null
      this.monto = 0
      this.$refs.form.resetValidation()
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
</style>
