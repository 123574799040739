var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"d-sm-flex align-center"},[_c('div',{staticClass:"ml-3"},[_c('h2',{staticClass:"font-weight-regular title"},[_vm._v("Matrículas")]),_c('h6',{staticClass:"body-2 mb-1 subtitle-2 font-weight-light"},[_vm._v("Datos de matrículas")])])]),_c('div',{staticClass:"d-flex align-center pa-3"},[_c('v-select',{staticClass:"mr-5",staticStyle:{"max-width":"210px"},attrs:{"label":"Tipo de pago","items":_vm.matriculaPaymentTypes,"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":""},on:{"change":function($event){_vm.filters.page = 1}},model:{value:(_vm.filters.tipoPago),callback:function ($$v) {_vm.$set(_vm.filters, "tipoPago", $$v)},expression:"filters.tipoPago"}}),_c('BaseDataRange',{staticStyle:{"max-width":"474px","margin":"0"},on:{"sendStartDate":_vm.sendStartDate,"sendEndDate":_vm.sendEndDate,"clearStartDate":function($event){_vm.filters.startDate=''},"clearEndDate":function($event){_vm.filters.endDate=''}}})],1),_c('v-data-table',{attrs:{"headers":_vm.headerArray,"items":_vm.matriculas,"page":_vm.filters.page,"items-per-page":_vm.pageSize,"server-items-length":_vm.totalPages,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)}},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.display_name)+" ")]),_c('h6',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.email)+" ")]),(item.fecha_matricula !== null)?_c('p',{staticClass:"font-weight-medium text-no-wrap ma-0  enrollment_date"},[_vm._v(" Matriculado el "+_vm._s(_vm._f("moment")(item.fecha_matricula,"DD/MM/YYYY [a las] HH:mm"))+" ")]):_vm._e()])])]}},{key:"item.seccion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.seccion.nombre)+" ")]),_c('h6',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.seccion.fecha_inicio,"DD/MM/YYYY"))+" ~ "+_vm._s(_vm._f("moment")(item.seccion.fecha_fin,"DD/MM/YYYY"))+" ")])])])]}},{key:"item.apps_activas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"}},[(item.usuario.apps_activas.length)?_vm._l((item.usuario.apps_activas),function(app,index){return _c('div',{key:index,staticClass:"app-chip mx-1",attrs:{"dense":""}},[_vm._v(" "+_vm._s(app)+" ")])}):_c('p',{staticClass:"mb-0"},[_vm._v("-")])],2)]}},{key:"item.pago_cuotas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('v-chip',{staticStyle:{"color":"white"},attrs:{"dense":"","variant":"flat","color":item.pago_cuotas ? 'primary': 'green'}},[_vm._v(" "+_vm._s(item.pago_cuotas ? 'En cuotas': 'Contado')+" ")])],1)])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center py-2"},[(item.pago_cuotas)?_c('v-btn',{staticClass:"actions-btn actions-btn--success mb-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.setMatricula(item)}}},[_vm._v("Enviar alerta")]):_vm._e(),_c('v-btn',{staticClass:"actions-btn mb-1",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.setPremiumUser(item.usuario)}}},[_vm._v("Dar membresía")]),_c('v-btn',{staticClass:"actions-btn actions-btn--secondary",attrs:{"x-small":""},on:{"click":function($event){return _vm.removePremium(item.usuario.id, item.usuario.apps_activas)}}},[_vm._v("Quitar membresía")])],1)]}},{key:"footer",fn:function(slotProps){return [_c('div',{staticClass:"pagination d-flex ml-auto mr-0 mt-5"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.filters.page === 1},on:{"click":function($event){_vm.filters.page > 1 ? _vm.filters.page--: ''}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('p',{staticClass:"ma-auto"},[_vm._v(_vm._s(slotProps.props.pagination.page)+" de "+_vm._s(_vm.totalPages))]),_c('v-btn',{attrs:{"icon":"","disabled":_vm.next === null},on:{"click":function($event){_vm.filters.page < _vm.totalPages ? _vm.filters.page++: ''}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]}}])})],1),_c('make-premium-modal',{attrs:{"user":_vm.userSelected},on:{"close":function($event){_vm.showMakePremiumModal = false},"updated":function($event){_vm.showMakePremiumModal = false; _vm.fetchMatriculas()}},model:{value:(_vm.showMakePremiumModal),callback:function ($$v) {_vm.showMakePremiumModal=$$v},expression:"showMakePremiumModal"}}),_c('payment-alert-modal',{attrs:{"matricula":_vm.matriculaSelected},on:{"close":function($event){_vm.showPaymentAlertModal = false},"updated":function($event){_vm.showPaymentAlertModal = false}},model:{value:(_vm.showPaymentAlertModal),callback:function ($$v) {_vm.showPaymentAlertModal=$$v},expression:"showPaymentAlertModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }