<template>
  <v-dialog
      v-bind="$attrs"
      v-on="$listeners"
      class="container"
      width="394"
  >
    <v-card class="container__card d-flex flex-column rounded-lg" width="100%">
      <v-form @submit.prevent="makePremium" ref="form">
        <div class="pt-11 pb-5 px-10">
          <p class="font-weight-regular title text-left pa-0 ma-0">Dar membresía</p>
        </div>
        <div class="container__item px-10">
          <v-checkbox
            v-model="appsSelected"
            hide-details
            label="Banqueo"
            value="Banqueo"
            dense
            class="pa-0 ma-0 mb-2"
            :error="!!errors.appsSelected"
            @change="errors.appsSelected = null"
          ></v-checkbox>
          <v-checkbox
            v-model="appsSelected"
            label="Flashcards"
            value="Flashcards"
            dense
            class="pa-0 ma-0 mb-2"
            :error="!!errors.appsSelected"
            :error-messages="errors.appsSelected"
            @change="errors.appsSelected = null"
          ></v-checkbox>
          <v-menu
            v-model="showStartDate"
            :close-on-content-click="false"
            :nudge-right="15"
            transition="scale-transition"
            offset-y
            min-width="auto"
            dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartDate"
                label="Fecha de inicio"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="showStartDate = false"
              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="showEndDate"
            :close-on-content-click="false"
            :nudge-right="15"
            transition="scale-transition"
            offset-y
            min-width="auto"
            dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="Fecha de fin"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="showEndDate = false"
              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="container__item pt-2 pb-11 px-9 d-flex justify-end">
          <v-btn class="mx-1" outlined @click="close">Cerrar</v-btn>
          <v-btn type="submit" :loading="loading" class="mx-2" color="primary">Guardar</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@/utils/rules'
export default {
  data() {
    return {
      loading: false,
      startDate: null,
      endDate: null,
      showStartDate: null,
      showEndDate: null,
      appsSelected: [],
      rules: {
        required
      },
      errors: {
        appsSelected: null
      }
    }
  },
  props: {
    user: Object
  },
  computed: {
    formattedStartDate () {
      return this.formatDate(this.startDate)
    },
    formattedEndDate () {
      return this.formatDate(this.endDate)
    },
  },
  methods: {
    async makePremium () {
      const validApps = this.validateAppsSelected()
      const validDates = this.$refs.form.validate()
      if (validApps && validDates) {
        try {
          this.loading = true
          const payload = {
            apps_names: this.appsSelected,
            fecha_inicio: `${this.startDate}T00:00`,
            fecha_fin: `${this.endDate}T00:00`,
          }
          await this.$http.post(`api/admin/matriculas/users/${this.user.id}/make_premium_in_apps/`, payload)
          this.resetForm()
          this.$emit('updated')
          this.loading = false
        } catch (e) {
          this.loading = false
          await this.$root.$confirm({
            title: '!Oops!',
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            acceptText: 'Ok',
          })
        }
      }
    },
    close () {
      this.resetForm()
      this.$emit('close')
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    validateAppsSelected () {
      const validate = this.appsSelected.length > 0
      if (!validate) {
        this.errors.appsSelected = 'Seleccione al menos una aplicación'
      }
      return validate
    },
    resetForm () {
      this.startDate = null
      this.endDate = null
      this.appsSelected = []
      this.errors.appsSelected = null
      this.$refs.form.resetValidation()
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
</style>
