<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex align-center">
        <div class="ml-3">
          <h2 class="font-weight-regular title">Matrículas</h2>
          <h6 class="body-2 mb-1 subtitle-2 font-weight-light">Datos de matrículas</h6>
        </div>
      </div>
    <!-- Header -->
    <div class="d-flex align-center pa-3">
      <v-select
        v-model="filters.tipoPago"
        label="Tipo de pago"
        class="mr-5"
        :items="matriculaPaymentTypes"
        item-text="text"
        item-value="value"
        outlined
        dense
        style="max-width: 210px;"
        hide-details
        @change="filters.page = 1"
      />
      <BaseDataRange
        style="max-width: 474px; margin: 0;"
        @sendStartDate="sendStartDate"
        @sendEndDate="sendEndDate"
        @clearStartDate="filters.startDate=''"
        @clearEndDate="filters.endDate=''"
      />
    </div>
    <!-- Fin header -->
    <!-- Table -->
      <v-data-table
        :headers="headerArray"
        :items="matriculas"
        :page.sync="filters.page"
        :items-per-page="pageSize"
        :server-items-length="totalPages"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:item.nombre="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.usuario.display_name }}
              </h4>
              <h6 class="font-weight-medium text-no-wrap">
                {{ item.usuario.email }}
              </h6>
              <p class="font-weight-medium text-no-wrap ma-0  enrollment_date" v-if="item.fecha_matricula !== null">
                Matriculado el {{ item.fecha_matricula | moment("DD/MM/YYYY [a las]  HH:mm")}}
              </p>
            </div>
          </div>
        </template>

        <template v-slot:item.seccion="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <p class="mb-0">
                {{ item.seccion.nombre }}
              </p>
              <h6 class="font-weight-medium text-no-wrap">
                {{ item.seccion.fecha_inicio | moment("DD/MM/YYYY") }} ~ {{ item.seccion.fecha_fin | moment("DD/MM/YYYY")}}
              </h6>
            </div>
          </div>
        </template>

        <template v-slot:item.apps_activas="{ item }">
          <div class="d-flex justify-center" style="cursor: pointer;">
            <template v-if="item.usuario.apps_activas.length">
              <div
                v-for="(app, index) in item.usuario.apps_activas"
                :key="index"
                dense
                class="app-chip mx-1"
              >
                {{ app }}
              </div>
            </template>
            <p v-else class="mb-0">-</p>
          </div>
        </template>

        <template v-slot:item.pago_cuotas="{ item }">
          <div class="d-flex justify-center" style="cursor: pointer;">
            <div class="my-auto">
              <v-chip dense style="color: white" variant="flat" :color="item.pago_cuotas ? 'primary': 'green'">
                {{ item.pago_cuotas ? 'En cuotas': 'Contado' }}
              </v-chip>
            </div>
          </div>
        </template>

        <template v-slot:item.acciones="{ item }">
          <div class="d-flex flex-column align-center py-2">
            <v-btn v-if="item.pago_cuotas" class="actions-btn actions-btn--success mb-1" x-small @click="setMatricula(item)">Enviar alerta</v-btn>
            <v-btn class="actions-btn mb-1" x-small color="primary" @click="setPremiumUser(item.usuario)">Dar membresía</v-btn>
            <v-btn class="actions-btn actions-btn--secondary" x-small @click="removePremium(item.usuario.id, item.usuario.apps_activas)">Quitar membresía</v-btn>
          </div>
        </template>

        <!-- customizing pagination -->
        <template v-slot:footer="slotProps">
            <div class="pagination d-flex ml-auto mr-0 mt-5">
                <v-btn icon @click="filters.page > 1 ? filters.page--: ''" :disabled="filters.page === 1"><v-icon>mdi-chevron-left</v-icon></v-btn>
                <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ totalPages }}</p>
                <v-btn icon @click="filters.page < totalPages ? filters.page++: ''" :disabled="next === null"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </template>
        <!-- customizing pagination -->
      </v-data-table>
    <!-- Table -->
    </v-card-text>

    <make-premium-modal 
      v-model="showMakePremiumModal" 
      :user="userSelected"
      @close="showMakePremiumModal = false"
      @updated="showMakePremiumModal = false; fetchMatriculas()"
    ></make-premium-modal>

    <payment-alert-modal
      v-model="showPaymentAlertModal"
      :matricula="matriculaSelected"
      @close="showPaymentAlertModal = false"
      @updated="showPaymentAlertModal = false"
    >
    </payment-alert-modal>
  </v-card>
</template>

<script>
// import NProgress from "nprogress"
import { mapMutations, mapState } from 'vuex'
import BaseDataRange from '@/components/commonComponents/BaseDataRange'
import MakePremiumModal from '@/components/dashboardComponents/matriculas/MakePremiumModal'
import PaymentAlertModal from '@/components/dashboardComponents/matriculas/PaymentAlertModal'

export default {
  name: "TheMatriculasTable",
  components: {
    BaseDataRange,
    MakePremiumModal,
    PaymentAlertModal
  },
  data: () => ({
    pageCount: 0,
    loading: false,
    matriculas: [],
    pageSize: 15,
    totalPages: 1,
    next: null,
    headerArray: [
      { text: 'Nombre', value: 'nombre', sortable: false, align: 'start' },
      { text: 'Seccion', value: 'seccion', sortable: false, align: 'start' },
      { text: 'Monto pagado', value: 'monto_pagado', sortable: false, align: 'center' },
      { text: 'Monto total', value: 'monto_total', sortable: false, align: 'center' },
      { text: 'Tipo de pago', value: 'pago_cuotas', sortable: false, align: 'center' },
      { text: 'Apps activas', value: 'apps_activas', sortable: false, align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false, align: 'center' }
    ],
    matriculaPaymentTypes: [
      {text: 'Todos', value: 'Todos'},
      {text: 'En cuotas', value: 'En cuotas'},
      {text: 'Al contado', value: 'Al contado'}
    ],
    timeout: null,
    filters: {
      startDate: '',
      endDate: '',
      page: 1,
      search: '',
      tipoPago: 'Todos'
    },
    userSelected: null,
    matriculaSelected: null,
  }),
  watch: {
    async searchGlobal () {
      this.fetchData()
    },
    filters: {
      deep: true,
      async handler () {
        await this.fetchData()
      }
    },
  },
  computed: {
    ...mapState(['searchGlobal']),
    showMakePremiumModal: {
      get () {
        return !!this.userSelected
      },
      set (val) {
        if (!val) {
          this.userSelected = null
        }
        return val
      }
    },
    showPaymentAlertModal: {
      get () {
        return !!this.matriculaSelected
      },
      set (val) {
        if (!val) {
          this.matriculaSelected = null
        }
        return val
      }
    }
  },
  methods: {
    ...mapMutations(['SET_SEARCH']),
    async fetchMatriculas() {
      const query = this.$route.query
      const url = `api/admin/matriculas/matriculas/?page_size=${this.pageSize}&page=${query.page || 1}&fecha_inicio=${query.fecha_inicio || ''}&fecha_fin=${query.fecha_fin || ''}&search=${query.search || ''}&tipo_pago=${query.tipo_pago || ''}`
      const matriculasResponse = await this.$http.get(url)
      this.next = matriculasResponse.data.next
      this.matriculas = matriculasResponse.data.results
      this.totalPages = Math.ceil(matriculasResponse.data.count / this.pageSize)
    },
    sendStartDate (item) {
      this.filters.page = 1
      this.filters.startDate = item
    },
    sendEndDate (item) {
      this.filters.page = 1
      this.filters.endDate = item
    },
    async fetchData () {
      clearTimeout(this.timeout)
      this.timeout = null
      this.timeout = setTimeout(async () => {
        await this.$router.replace(
          {
            path: this.$route.path,
            query: {
              fecha_inicio: this.filters.startDate,
              fecha_fin: this.filters.endDate,
              page: this.filters.page,
              search: this.searchGlobal,
              tipo_pago: this.filters.tipoPago,
            },
            scrollBehavior: {top: 0}
          },
        )
        await this.fetchMatriculas()
      }, 600)
    },
    setInitialQueryParams () {
      this.filters = {
        startDate: this.$route.query.fecha_inicio,
        endDate: this.$route.query.fecha_fin,
        page: this.$route.query.page || 1,
        tipoPago: this.$route.query.tipo_pago
      }
    },
    async setPremiumUser (user) {
      this.userSelected = user
    },
    async setMatricula (matricula) {
      this.matriculaSelected = matricula
    },
    async removePremium (userId, apps_names) {
      const confirm = await this.$root.$confirm({
        title: 'Mensaje de confirmación',
        message: '¿Estás seguro que deseas quitar la membresía a este usuario?',
        acceptText: 'Aceptar',
        cancelText: 'Cancelar'
      })
      if (!confirm) return

      try {
        this.loading = true
        const payload = { apps_names }
        await this.$http.post(`api/admin/matriculas/users/${userId}/delete_premium_in_apps/`, payload)

        await this.$root.$confirm({
          title: 'Mensaje',
          message: 'Se ha quitado la membresía a este usuario.',
          acceptText: 'Aceptar',
        })
        await this.fetchMatriculas()
        this.loading = false
      } catch (e) {
        this.loading = false
        await this.$root.$confirm({
          title: '!Oops!',
          message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
          acceptText: 'Ok',
        })
      }

    }
  },
  async mounted() {
    if (this.$route.query.search) {
      await this.SET_SEARCH(this.$route.query.search)
    }
    this.setInitialQueryParams()
    await this.fetchMatriculas()
  }
};
</script>

<style lang="scss" scoped>
.enrollment_date {
  color: #1E88E5;
  font-size: 12px;
}

.app-chip {
  font-size: 10px;
  color: #FFF;
  background-color: rgba(0,0,0,.54);
  border-radius: 5px;
  padding: 5px;
}

.actions-btn {
  text-transform: none;
  letter-spacing: 0;
  width: 112px;
  min-width: 112px;

  &--secondary {
    background: rgba(0,0,0,.54) !important;
    color: white;
  }

  &--success {
    background: #4CAF50 !important;
    color: white;
  }
}
</style>
